import {useEffect, useState} from "react";
import {UsePaginateHock} from "../../hooks/usePaginateHock";
import {buildUrlClient} from "../../utils/BuildUrlClient";
import ReactPaginate from "react-paginate";
import {ScheduleDetail} from "./ScheduleDetail";

export const ScheduleActive = () => {
  const {list, removeItem, getDataList, addParams, total, page} = UsePaginateHock(buildUrlClient('scheduledActiveClient'));
  const [item, setItem] = useState(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    (async () => await getDataList(0))();
  }, []);
  const onDetail = (item: any) => {
    setItem({...item});
    setVisible(true);
  }
  const onPageChange = async (page: any) => {
    await getDataList(page.selected)
  }
  const onClose = () => {
    setVisible(false);
    setItem(null);
  }
  return <>
    <div>
    <div className="table-responsive border-0">
      <table className="table table-dark-gray align-middle p-4 mb-0 table-hover">
        <thead>
        <tr>
          <th scope="col" className="border-0 rounded-start">Nro.</th>
          <th scope="col" className="border-0">Cita</th>
          <th scope="col" className="border-0">Fecha</th>
          <th scope="col" className="border-0">Costo</th>
          <th scope="col" className="border-0">Transporte </th>
          <th scope="col" className="border-0">Descuento</th>
          <th scope="col" className="border-0">Canjeo</th>
          <th scope="col" className="border-0">Total</th>
          <th scope="col" className="border-0 rounded-end">Acciones</th>
        </tr>
        </thead>
        <tbody>
        {list.map((item: any, index: number)=>
        <tr key={index}>
          <td>{item?.id}</td>
          <td>
            <h6 className="table-responsive-title mb-0"><a href="#">{item.Cita}</a></h6>
          </td>
          <td>{item?.date_appointment}</td>
          <td>
            {item?.total_cost}
          </td>
          <td>
            {item?.transport_cost}
          </td>
          <td>
            ${item?.discount}
          </td>
          <td>
            <div className="badge bg-success bg-opacity-10 text-success">   ${item.exchange_amount}</div>
          </td>
          <td>
            <div className="badge bg-success bg-opacity-10 text-success"> {((Number(item.total_cost) - Number(item.discount)  - Number(item.exchange_amount)) + Number(item.transport_cost)).toFixed(2) }</div>
          </td>

          <td>
            <button  className="btn btn-primary-soft btn-round me-1 mb-1 mb-md-0"
                     onClick={() => onDetail(item)}
                     data-bs-toggle="tooltip"
               data-bs-placement="top" title="" data-bs-original-title="Detalle" aria-label="Detalle">
              <i className="bi bi-arrow-right"></i>
            </button>
          </td>
        </tr>

          )}
        </tbody>
      </table>
    </div>
      <div className="card-footer bg-transparent pt-0">
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <p className="mb-0 text-center text-sm-start">Total páginas {total}</p>
          <ReactPaginate
              breakLabel="..."
              nextLabel="Siguiente >"
              onPageChange={(page) => onPageChange(page) }
              pageRangeDisplayed={5}
              pageCount={total}
              previousLabel="< Anterior"
              renderOnZeroPageCount={null}
              containerClassName={"pagination pagination-sm pagination-primary-soft d-inline-block d-md-flex rounded mb-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
          />
        </div>
      </div>
    </div>
    {visible && <ScheduleDetail visible={visible} item={item} onClose={() => onClose()} /> }
  </>
}
